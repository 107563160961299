import React, { useState } from 'react'
import { Wheel } from 'react-custom-roulette'
import rouletteImage from './assets/roulette.png'
import tpsCursor from './assets/tpscursor.png'
import ikiliTesterMama from './assets/ikili-tester+mama.png'
import ikiTesterTohumKarti from './assets/ikili-tester-+tohum-karti.png'
import bezCantaIkılıTesterMama from './assets/bez-canta+ikili-tester+mama.png'
import bezCantaCiltBakimBilekligiIkılıTester from './assets/bez-canta+cilt-bakim-bilekligi+ikili-tester.png'
import ikiliTesterTohumKartiCiltBakimBilekligi from './assets/ikili-tester+tohum-karti+cilt-bakim-bilekligi.png'
import testMeKitiCiltBakimBilekligi from './assets/test-me-kiti+cilt-bakim-bilekligi.png'
import testMeKitiSansBilekligi from './assets/test-me-kiti+sans-bilekligi.png'
import testMeKitiSansBilekligiBezCanta from './assets/test-me-kiti+sans-bilekligi+bez-canta.png'
import tohumKartiBezCantaTestMeKitiMama from './assets/tohum-karti+bez-canta+test-me-kiti+mama.png'
import tohumKartiBezCantaTestMeKitiCiltBakimBilekligi from './assets/tohum-karti+bez-canta+test-me-kiti+cilt-bakim-bilekligi.png'

const pointerObj = {
  src: tpsCursor,
}

const data = [
  {
    option: 'Tester + Mama',
    style: { backgroundColor: '#262262', fontSize: '15' },
  },
  {
    option: 'Tester + Tohum Kartı',
    style: { backgroundColor: '#eac3c6', fontSize: '15' },
  },
  {
    option: 'Çanta + Tester + Mama',
    style: { backgroundColor: '#8f489b', fontSize: '15' },
  },
  {
    option: 'Çanta + Bileklik + Tester',
    style: { backgroundColor: '#056839', fontSize: '15' },
  },
  {
    option: 'Tester+Tohum Kartı+Bileklik',
    style: { backgroundColor: '#f7941d', fontSize: '13' },
  },
  {
    option: 'Test Me + Bileklik',
    style: { backgroundColor: '#b97bb5', fontSize: '15' },
  },
  {
    option: 'Test Me + Bileklik',
    style: { backgroundColor: '#692046', fontSize: '15' },
  },
  {
    option: 'Test Me + Bileklik + Çanta',
    style: { backgroundColor: '#74bd43', fontSize: '15' },
  },
  {
    option: 'Tohum+Çanta+TestMe+Mama',
    style: { backgroundColor: '#faaa64', fontSize: '13' },
  },
  {
    option: 'Tohum+Çanta+TestMe+Bileklik',
    style: { backgroundColor: '#00aeef', fontSize: '13' },
  },
]

const products = [
  {
    id: 0,
    name: ['İkili Tester', ' Mama'],
    img: ikiliTesterMama,
  },
  {
    id: 1,
    name: ['İkili Tester', 'Tohum Kartı'],
    img: ikiTesterTohumKarti,
  },
  {
    id: 2,
    name: ['Bez Çanta', 'İkili Tester', 'Mama'],
    img: bezCantaIkılıTesterMama,
  },
  {
    id: 3,
    name: ['Bez Çanta', 'Cilt Bakım Bilekliği ', ' İkili Tester'],
    img: bezCantaCiltBakimBilekligiIkılıTester,
  },
  {
    id: 4,
    name: ['İkili Tester', 'Tohum Kartı', 'Cilt Bakım Bilekliği'],
    img: ikiliTesterTohumKartiCiltBakimBilekligi,
  },
  {
    id: 5,
    name: ['Test Me Kiti ', 'Cilt Bakım Bilekliği'],
    img: testMeKitiCiltBakimBilekligi,
  },
  {
    id: 6,
    name: ['Test Me Kiti', ' Şans Bilekliği'],
    img: testMeKitiSansBilekligi,
  },
  {
    id: 7,
    name: ['Test Me Kiti', 'Şans Bilekliği', 'Bez Çanta'],
    img: testMeKitiSansBilekligiBezCanta,
  },
  {
    id: 8,
    name: ['Tohum Kartı', 'Bez Çanta', 'Test Me Kiti', 'Mama'],
    img: tohumKartiBezCantaTestMeKitiMama,
  },
  {
    id: 9,
    name: ['Tohum Kartı', 'Bez Çanta', 'Test Me Kiti', 'Cilt Bakım Bilekliği'],
    img: tohumKartiBezCantaTestMeKitiCiltBakimBilekligi,
  },
]

export default () => {
  const [mustSpin, setMustSpin] = useState(false)
  const [prizeNumber, setPrizeNumber] = useState(10)

  const [select, setSelect] = useState({
    product: '',
    background: '',
    img: null,
  })

  function generateRandomLinear() {
    const probabilities = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1]
    const totalProbability = probabilities.reduce((acc, val) => acc + val, 0)
    const randomNum = Math.random() * totalProbability

    let cumulativeProbability = 0
    for (let i = 0; i < probabilities.length; i++) {
      cumulativeProbability += probabilities[i]
      if (randomNum < cumulativeProbability) {
        return i
      }
    }

    return 3
  }

  const handleSpinClick = async () => {
    if (!mustSpin) {
      const newPrizeNumber = Math.floor(Math.random() * data.length)
      // const newPrizeNumber = await generateRandomLinear()
      setPrizeNumber(newPrizeNumber)
      setMustSpin(true)
      let select = products.find((item) => item.id === newPrizeNumber)
      let selectData = data.find((item, i) => i === newPrizeNumber)

      await setSelect({
        product: select.name,
        background: selectData.style.backgroundColor,
        img: select.img,
      })
    }
    setPopupCSS(true)

    setTimeout(() => {
      setPopup(true)
    }, 6000)
  }

  const [popup, setPopup] = useState(false)
  const [popupCSS, setPopupCSS] = useState(false)

  return (
    <>
      <div
        className='popup'
        style={{
          backgroundColor: select.background ? select.background : '#F6F6F6',
          border: popup ? `2px solid ${select.background}` : 'none',
          width: '600px',
          maxWidth: '130%',
          height: popup ? '300px' : '0px',
          borderRadius: '16px',
          fontSize: '30px',
          position: 'absolute',
          color: '#F0F0F0',
          transform: 'translate(-50%, 50%)',
          zIndex: popupCSS ? 6 : -1,
          transitionDuration: '750ms',
          opacity: popupCSS ? 1 : 0,
          overflow: 'hidden',
          backfaceVisibility: 'hidden',
          willChange: 'height',
          boxShadow: '0px 0px 6px rgba(255, 255, 255, 0.5)',
        }}
      >
        <button
          onClick={() => {
            setPopup(false)
            setPopupCSS(false)
          }}
          style={{
            position: 'absolute',
            right: 5,
            top: 5,
            border: 'none',
            backgroundColor: 'transparent',
            fontSize: '20px',
            cursor: 'pointer',
            zIndex: 3,
            color: '#FFFFFF',
          }}
        >
          x
        </button>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: popup ? 1 : 0,
            transition: 'opacity 3s ease',
          }}
        >
          <div
            style={{
              width: '70%',
              borderRadius: '8px',
              borderTopRightRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{ borderRadius: '8px', marginLeft: '11px' }}
              src={select.img}
              width='100%'
              height='290px'
              alt=''
            />
          </div>

          <div
            style={{
              width: '30%',
              textAlign: 'center',
              color: 'white',
              padding: '10px',
              fontSize: '20px',
            }}
          >
            {popupCSS &&
              select.product.map((item) => (
                <span
                  style={{
                    display: 'block',
                    margin: '25px 0',
                    fontSize: '30px',
                  }}
                >
                  {item}
                </span>
              ))}
          </div>
        </div>
      </div>

      <div
        style={{
          width: '100%',
          bottom: 0,
          right: 0,
          position: 'absolute',
        }}
      ></div>
      <div
        style={{
          position: 'relative',
          height: '420px',
        }}
      >
        <div
          style={{
            width: '553px',
            height: '495px',
          }}
        >
          <div style={{ position: 'absolute' }}>
            <img
              src={rouletteImage}
              style={{ width: '112%' }}
              alt='rouletteImage'
            />
          </div>

          <Wheel
            mustStartSpinning={mustSpin}
            prizeNumber={prizeNumber}
            data={data}
            onStopSpinning={() => {
              setMustSpin(false)
            }}
            backgroundColors={['#3e3e3e', '#df3428']}
            textColors={['#ffffff']}
            // outerBorderColor={['red']}
            outerBorderWidth={0}
            innerRadius={0}
            // innerBorderColor='white'
            // innerBorderWidth={40}
            radiusLineColor='transparent'
            radiusLineWidth={1}
            fontFamily='Arial'
            fontSize={14}
            fontWeight='500'
            fontStyle='normal'
            // perpendicularText={true}
            textDistance='58'
            spinDuration='0.5'
            // disableInitialAnimation={false}
            pointerProps={pointerObj}
          />
        </div>
        <div
          style={{
            position: 'absolute',
            left: '56.2%',
            bottom: '15%',
            transform: 'translate(-50%, -50%)',
            zIndex: 5,
          }}
        >
          <button
            style={{
              width: '50px',
              height: '50px',
              borderRadius: '50%',
              cursor: 'pointer',
              color: 'white',
              fontWeight: 700,
              fontSize: '15px',
              background: 'radial-gradient(#6d889d, #425268)',
              border: 'none',
            }}
            disabled={mustSpin}
            onClick={handleSpinClick}
          >
            Çevir
          </button>
        </div>
      </div>
    </>
  )
}
